<template>
  <a-card>
    <a-row :gutter="15">
      <a-col span="6">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
        >
          <a-row>
            <a-form-model-item label="问题名称" prop="problemName">
              <a-input placeholder="请输入问题名称" v-model="form.problemName" :disabled="!canModify"/>
            </a-form-model-item>
            <a-form-model-item label="问题级别" prop="problemLevel">
              <a-select v-model="form.problemLevel" placeholder="请选择问题级别" :disabled="!canModify">
                <a-select-option :value="1">紧急&严重</a-select-option>
                <a-select-option :value="2">紧急</a-select-option>
                <a-select-option :value="3">严重</a-select-option>
                <a-select-option :value="4">普通</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="状态" prop="problemStatus">
              <a-select v-model="form.problemStatus" placeholder="请选择状态" :disabled="!canModify">
                <a-select-option :value="0">进行中</a-select-option>
                <a-select-option :value="1">已解决</a-select-option>
                <a-select-option :value="2">已关闭</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="被分配者" prop="assignerId">
              <a-select v-model="form.assignerId" placeholder="请选择被分配者" :disabled="!canModify">
                <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联任务" prop="taskId">
              <a-select v-model="form.taskId" placeholder="请选择关联任务" :disabled="!canModify">
                <a-select-option :value="item.id" v-for="(item, index) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
      </a-col>
      <a-col span="18">
        <EditProblem :id="id" :problemId="problemId" v-if="problemId" :canModify="canModify"></EditProblem>
      </a-col>
    </a-row>
    <a-row class="margin-t20">
      <div v-has-permission="['editProblem']">
        <div>发言：</div>
        <QuillEditor v-model="message" style="margin-top: 15px; height: 500px"></QuillEditor>
        <div class="margin-t10 display-f justify-end">
          <a-button type="primary" class="width-100" @click="leaveAMessage">发送</a-button>
        </div>
      </div>
      <div class="">
        <div class="display-f margin-t20" v-for="(item, index) in form.problemMessages" :key="item.id">
          <img src="@/assets/image/common/header.png" alt="" class="width-40 height-40">
          <div class="margin-l20 flex1">
            <div class="">
              <span class="font-wb">{{ item.creator }}</span>
              <span class="margin-l10 font-c9">{{ item.createTime }}</span>
            </div>
            <div class="margin-t10" v-html="item.messageContent"></div>
          </div>
        </div>
      </div>
    </a-row>
    <div class="margin-t15 display-f justify-end" v-has-permission="['editProblem']">
      <a-button type="primary" class="width-100" @click="onSubmit">保存</a-button>
      <a-button class="width-100" @click="onCancel" style="margin-left: 20px">取消</a-button>
    </div>
  </a-card>
</template>

<script>
import QuillEditor from '@/components/Editor/QuillEditor'
import EditProblem from './editProblem'
import { getProjectList } from '@/api/project/project'
import { createProblem, getProblemDetail } from '@/api/problem/problem'
import { getTaskList, getTaskMember } from '@/api/task/task'
import { leaveAMessage } from '../../../api/problem/problem'
import { mapState } from 'vuex'
import { getProjectMember } from '../../../api/task/task'
export default {
  name: 'problemDetail',
  props: {
    id: {
      type: String,
      default: ''
    },
    problemId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    QuillEditor,
    EditProblem
  },
  data () {
    return {
      columns: [
        {
          title: '问题名称',
          dataIndex: 'problemName'
        },
        {
          title: '问题级别',
          dataIndex: 'problemLevel',
          scopedSlots: { customRender: 'problemLevel' }
        },
        {
          title: '状态',
          dataIndex: 'problemStatus',
          scopedSlots: { customRender: 'problemStatus' }
        },
        {
          title: '关联任务',
          dataIndex: 'taskName'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '提报人',
          dataIndex: 'creator'
        },
        {
          title: '被分配者',
          dataIndex: 'assigner'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      form: {},
      projectList: [],
      memberList: [],
      taskList: [],
      rules: {
        problemName: [
          { required: true, message: '请输入问题名称', trigger: 'blur' }
        ],
        problemLevel: [
          { required: true, message: '请选择问题级别', trigger: 'change' }
        ],
        problemStatus: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ]
      },
      message: '',
      canModify: true
    }
  },
  mounted () {
    this.getDetail()
    this.getProject()
    this.getProjectMember()
    this.getTask()
    this.canModify = this.authority.editProblem
  },
  methods: {
    getDetail () {
      getProblemDetail({
        id: this.problemId
      }).then(res => {
        this.form = res.data
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createProblem(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onCancel () {
      this.$emit('cancel', '')
    },
    getProject () {
      getProjectList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.projectList = res.data.list
      })
    },
    getProjectMember () {
      // getTaskMember({
      //   ids: [this.id]
      // }).then(res => {
      //   this.memberList = res.data
      // })
      getProjectMember({
        projectId: this.form.projectId
      }).then(res => {
        this.memberList = res.data
      })
    },
    getTask () {
      getTaskList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskList = res.data.list
      })
    },
    leaveAMessage () {
      leaveAMessage({
        problemId: this.problemId,
        messageContent: this.message
      }).then(() => {
        this.message = ''
        this.$message.success('发言成功！')
        this.getDetail()
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
